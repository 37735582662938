import { IonContent, IonText } from '@ionic/react';
import './style.css';

const PrivacyPolicy = ({ name }) => {
  return (
    <IonContent className='ion-padding container'>
      <h3>Effective Date: April 8, 2022</h3>
      <div className='paragraph'>
        At ono online, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. By using or accessing
        our Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will
        collect, use and share your information as described in this Privacy Policy.
      </div>
      <div className='paragraph'>
        Remember that your use of ono online's Services is at all times subject to our Terms of Use, which incorporates this Privacy Policy. Any terms
        we use in this Policy without defining them have the definitions given to them in the Terms of Use.
      </div>
      <div className='paragraph'>
        If you have a disability, you may access this Privacy Policy in an alternative format by contacting info@onoonline.co.
      </div>
      <h3>What this Privacy Policy Covers</h3>
      <div className='paragraph'>
        This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal Data” means any
        information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable
        information” or “personal information” under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the
        practices of companies we don’t own or control or people we don’t manage.
      </div>
      <h3>Personal Data</h3>
      <div className='paragraph'>Categories of Personal Data We Collect</div>
      <div className='paragraph'>
        This paragraph details the categories of Personal Data that we collect and have collected over the past 12 months:
      </div>
      <div className='paragraph'>
        Profile data. When you create an Account, complete your Account profile, sign up for a mailing list, or otherwise communicate or interact with
        us, we may collect information from you, such as your name, phone number, e-mail address.
      </div>
      <h3>Payment information.</h3>
      <div className='paragraph'>
        When you submit an order, you are required to provide payment information. For example, the last four digits of your credit card number,
        credit card type, expiration number, and default currency. For corporate Accounts that are using the service “merchants portal”, we may also
        have corporate credit card numbers or other payment account numbers. We use this payment information to fulfill your order, complete the
        transaction, detect and prevent fraud.
      </div>
      <h3>Transaction information.</h3>
      <div className='paragraph'>
        When you submit an order, we collect information, including the items purchased, date and time of your transaction, special instructions, and
        ono online credits you redeem as part of our loyalty, rewards or referral program. We use this transaction information to fulfill your order,
        update your ono online credits balance, personalize your experience, detect and prevent fraud, perform analytics/identify usage trends, and
        inform our advertising and marketing.
      </div>
      <h3>Location Data.</h3>
      <div className='paragraph'>
        With your permission, we may access your geo-location information, postal or zip code, and country. This information is generally collected
        from you and is used to provide you with a seamless experience by showing nearby Restaurants (defined below).
      </div>
      <h3>Communications, Participation and other content.</h3>
      <div className='paragraph'>
        We maintain all communications resulting from your interactions with us by email, phone, mail or chat. When you respond to our surveys or take
        part in our promo campaigns, we also record your comments. We use all of the foregoing communications to address your questions, issues and
        concerns, and to provide other related customer support services. We also use it for safety and security purposes, and for analytics.
      </div>
      <h3>Technical Data.</h3>
      <div className='paragraph'>
        For example, your Internet Protocol (IP) address, Device ID or MAC address, information about the manufacturer, model, settings, and operating
        system of your mobile device, and application version. This information is collected from you and third parties.
      </div>
      <h3>Other personal information you provide.</h3>
      <div className='paragraph'>
        ono online may collect your dietary preferences and allergies to provide a personalized experience to you and customize Restaurant and food
        types based on your preferences.
      </div>
      <div className='paragraph'>Categories of Sources of Personal Data</div>{' '}
      <div className='paragraph'>We collect Personal Data about you from the following categories of sources:</div>
      <h3>You</h3>
      <div className='paragraph'>When you provide such information directly to us.</div>{' '}
      <div className='paragraph'>When you create an account or use our interactive tools and Services.</div>
      <div className='paragraph'>
        When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.
      </div>
      <div className='paragraph'>When you send us an email or otherwise contact us.</div>{' '}
      <div className='paragraph'>When you use the Services and such information is collected automatically.</div>
      <div className='paragraph'>Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section below).</div>
      <div className='paragraph'>
        If you download our mobile application or use a location-enabled browser, we may receive information about your order history, location and
        mobile device, as applicable.
      </div>
      <div className='paragraph'>
        If you download and install certain applications and software we make available, we may receive and collect information transmitted from your
        computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to
        receive updates or alert notices.
      </div>
      <h3>Third Parties</h3>
      <div className='paragraph'>Vendors</div>
      <div className='paragraph'>
        We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with
        customer support.
      </div>
      <div className='paragraph'>Advertising Partners</div>
      <div className='paragraph'>
        We receive information about you from some of our vendors who assist us with marketing or promotional services related to how you interact
        with our websites, applications, products, Services, advertisements or communications.
      </div>
      <div className='paragraph'>Social Networks</div>
      <div className='paragraph'>
        If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, some
        content and/or information in those accounts may be transmitted into your account with us.
      </div>
      <div className='paragraph'>Our Commercial or Business Purposes for Collecting Personal Data</div>
      <div className='paragraph'>
        <span className='bold'>Providing, Customizing, Developing and Improving the Services.</span> We may use your information to provide, improve,
        develop and maintain the platform and Services. It includes:
      </div>
      <div className='paragraph'>Creating and managing your account or otherwise use the Platform and Services.</div>
      <div className='paragraph'>
        Providing you with the products, services or information you request (e.g. to allow you to order from Restaurants).
      </div>
      <div className='paragraph'>Improving the Services, including testing, research, internal analytics and product development.</div>{' '}
      <div className='paragraph'>Personalizing the Services, website content and communications based on your preferences.</div>{' '}
      <div className='paragraph'>Doing fraud protection, security and debugging.</div>
      <div className='paragraph'>
        Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such
        as the California Consumer Privacy Act (the “CCPA”).
      </div>{' '}
      <div className='paragraph'>
        <span className='bold'>Providing, Personalizing, Improving and Measuring our Marketing and Advertising.</span> We may use your personal
        information to provide, improve, personalize, measure and improve our marketing and advertising. For example, we may use your information to:
      </div>
      <div className='paragraph'>
        Manage and determine the effectiveness of our Services, surveys, rewards, promotional campaigns and other promotional activities.
      </div>
      <div className='paragraph'>
        Inform you of products, promotions and services that we believe may be of interest to you, including, without limitation, through emails and
        push notifications.
      </div>
      <div className='paragraph'>Showing you advertisements, including interest-based or online behavioral advertising.</div>
      <div className='paragraph'>
        <span className='bold'>Corresponding with You.</span> We may use your personal information to provide you with support and assistance for the
        Services. For example:
      </div>
      <div className='paragraph'>
        Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about ono
        online or the Services.
      </div>
      <div className='paragraph'>
        Communicating important messages regarding your orders, account, rewards, etc. Sending you service-related emails or text messages (e.g. user
        Account verification, order status updates, technical and security notices) and other communications from ono online.
      </div>
      <div className='paragraph'>
        Sending emails and other communications according to your preferences or that display content that we think will interest you.
      </div>
      <div className='paragraph'>Sharing your contact information with Restaurants, so that they may correspond with you.</div>
      <div className='paragraph'>
        <h5>Meeting Legal Requirements and Enforcing Legal Terms.</h5>Fulfilling our legal obligations under applicable law, regulation, court order
        or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.
      </div>
      <div className='paragraph'>Protecting the rights, property or safety of you, ono online or another party.</div>
      <div className='paragraph'>Enforcing any agreements with you.</div>
      <div className='paragraph'>Responding to claims that any posting or other content violates third-party rights.</div>
      <div className='paragraph'>Resolving disputes.</div>
      <div className='paragraph'>
        We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or
        incompatible purposes without providing you notice.
      </div>
      <div className='paragraph'>
        <h3>How We Share Your Personal Data</h3>We may disclose your Personal Data to the categories of service providers and other parties listed in
        this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data.
        For more information, please refer to the state-specific sections below.
      </div>
      <div className='paragraph'>
        <span className='bold'>Restaurants.</span> We share personal information to the retail food establishments (including restaurants, delis,
        bakeries, and coffee shops, together “Restaurants”) you order from and, if applicable, with third parties engaged by those Restaurants (e.g.
        point of sale providers), as needed, to fulfill your orders, or communicate with you about the status of your order, cancel items or any other
        issues related to your order. If you place an order at Restaurant that offers loyalty or rewards programs, we may disclose your personal
        information to the Restaurant to facilitate your participation in the Restaurant's loyalty or rewards program.
      </div>
      <div className='paragraph'>
        <span className='bold'>Service Providers.</span> These parties help us provide the Services or perform business functions on our behalf. For
        example, hosting, technology and communication providers, security and fraud prevention consultants, support and customer service vendors,
        email delivery services, advertising and marketing, and other similar services. These third-party service providers usually have access to
        your Personal Information only for the purpose of performing services on our behalf and are generally obligated not to disclose or use your
        Personal Information for any other purpose.
      </div>
      <div className='paragraph'>
        <span className='bold'>Analytics Partners.</span> We may share your personal information with third-party analytics providers to better
        understand, personalize, improve and enhance our Services. companies that track how users found or were referred to the Services and companies
        that track how users interact with the Services.
      </div>
      <div className='paragraph'>
        <span className='bold'>Business Partners.</span> We may share your personal information with companies, including but not limited to
        Restaurants we partner with to offer joint promotional offers and opportunities.
      </div>
      <div className='paragraph'>
        <span className='bold'>Parties You Authorize, Access or Authenticate.</span> For example, third parties you access through the services,
        social media services, other ono online users.
      </div>
      <div className='paragraph'>Legal Obligations</div>
      <div className='paragraph'>
        We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under “Meeting Legal
        Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for Collecting Personal Data” section above.
      </div>
      <div className='paragraph'>Business Transfers</div>
      <div className='paragraph'>
        All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other
        transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make
        reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.
      </div>
      <div className='paragraph'>Data that is Not Personal Data</div>
      <div className='paragraph'>
        We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the
        data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third
        parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will
        not share such data in a manner that could identify you.
      </div>
      <div className='paragraph'>
        <h3>Tracking Tools, Advertising and Opt-Out</h3> The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs
        and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and use our
        Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files
        – placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the
        information we collect from you with information received from third parties, including third parties that have placed their own Cookies on
        your device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at
        this time.
      </div>
      <div className='paragraph'>We use the following types of Cookies:</div>
      <div className='paragraph'>
        Essential Cookies: Essential Cookies are required for providing you with features or services that you have requested. For example, certain
        Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.
      </div>
      <div className='paragraph'>
        Functional Cookies: Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over
        time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and
        remember your preferences (for example, your choice of language or region).
      </div>
      <div className='paragraph'>
        Performance/Analytical Cookies: Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by
        collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are
        viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to
        help us improve our campaigns and the Services’ content for those who engage with our advertising. For example, Google Inc. (“Google”) uses
        cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about
        your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of
        Google’s use of Cookies by visiting the Google advertising opt-out page at www.google.com/privacy_ads.html or the Google Analytics Opt-out
        Browser Add-on at https://tools.google.com/dlpage/gaoptout/.
      </div>
      <div className='paragraph'>
        Retargeting/Advertising Cookies: Retargeting/Advertising Cookies collect data about your online activity and identify your interests so that
        we can provide advertising that we believe is relevant to you.
      </div>
      <div className='paragraph'>
        You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the
        Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser
        software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your
        device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services
        and functionalities may not work.
      </div>
      <div className='paragraph'>
        To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more
        information about Cookies, including information about how to manage and delete Cookies, please visit http://www.allaboutcookies.org/.
      </div>
      <div className='paragraph'>Information about Interest-Based Advertisements:</div>
      <div className='paragraph'>
        We may serve advertisements, and also allow third-party ad networks, including third-party ad servers, ad agencies, ad technology vendors and
        research firms, to serve advertisements through the Services. These advertisements may be targeted to users who fit certain general profile
        categories or display certain preferences or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal Data)
        may be provided to us by you, or derived from the usage patterns of particular users on the Services and/or services of third parties. Such
        information may be gathered through tracking users’ activities across time and unaffiliated properties, including when you leave the Services.
        To accomplish this, we or our service providers may deliver Cookies, including a file (known as a “web beacon”) from an ad network to you
        through the Services. Web beacons allow ad networks to provide anonymized, aggregated auditing, research and reporting for us and for
        advertisers. Web beacons also enable ad networks to serve targeted advertisements to you when you visit other websites. Web beacons allow ad
        networks to view, edit or set their own Cookies on your browser, just as if you had requested a web page from their site.
      </div>
      <div className='paragraph'>
        We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for Online Behavioral Advertising. Through the DAA and
        Network Advertising Initiative (“NAI”), several media and marketing associations have developed an industry self-regulatory program to give
        consumers a better understanding of, and greater control over, ads that are customized based a consumer’s online behavior across different
        websites and properties. To make choices about Interest-Based Ads from participating third parties, including to opt-out of receiving
        behaviorally targeted advertisements from participating organizations, please visit the DAA’s or NAI’s consumer opt-out pages, which are
        located at http://www.networkadvertising.org/choices/ or www.aboutads.info/choices.
      </div>
      <div className='paragraph'>
        <h3>Data Security and Retention</h3>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate
        physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that
        data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting
        access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the
        security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or
        storing data is completely secure.
      </div>
      <div className='paragraph'>
        We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services.
        In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect
        fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or
        aggregated form where that information would not identify you personally.
      </div>
      <div className='paragraph'>
        If you downloaded the ono online app through an app store and created an account, you may be able to delete your account data through the
        account settings. This will not apply to account information that you have separately shared with ono online, outside of our app.
      </div>
      <div className='paragraph'>
        <h3>Personal Data of Children</h3>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 16
        years of age; if you are a child under the age of 16, please do not attempt to register for or otherwise use the Services or send us any
        Personal Data. If we learn we have collected Personal Data from a child under 16 years of age, we will delete that information as quickly as
        possible. If you believe that a child under 16 years of age may have provided Personal Data to us, please contact us at info@onoonline.co.
      </div>
      <div className='paragraph'>
        <h3>California Resident Rights</h3> If you are a California resident, you have the rights set forth in this section. Please see the
        “Exercising Your Rights” section below for instructions regarding how to exercise these rights. Please note that we may process Personal Data
        of our customers’ end users or employees in connection with our provision of certain services to our customers. If we are processing your
        Personal Data as a service provider, you should contact the entity that collected your Personal Data in the first instance to address your
        rights with respect to such data.
      </div>
      <div className='paragraph'>
        If there are any conflicts between this section and any other provision of this Privacy Policy and you are a California resident, the portion
        that is more protective of Personal Data shall control to the extent of such conflict. If you have any questions about this section or whether
        any of the following rights apply to you, please contact us at legal@onoonline.co.
      </div>
      <div className='paragraph'>Access</div>
      <div className='paragraph'>
        You have the right to request certain information about our collection and use of your Personal Data over the past 12 months. In response, we
        will provide you with the following information:
      </div>
      <div className='paragraph'>The categories of Personal Data that we have collected about you.</div>
      <div className='paragraph'>The categories of sources from which that Personal Data was collected.</div>
      <div className='paragraph'>The business or commercial purpose for collecting or selling your Personal Data.</div>
      <div className='paragraph'>The categories of third parties with whom we have shared your Personal Data.</div>
      <div className='paragraph'>The specific pieces of Personal Data that we have collected about you.</div>
      <div className='paragraph'>
        If we have disclosed your Personal Data to any third parties for a business purpose over the past 12 months, we will identify the categories
        of Personal Data shared with each category of third-party recipient. If we have sold your Personal Data over the past 12 months, we will
        identify the categories of Personal Data sold to each category of third party recipient.
      </div>
      <div className='paragraph'>Deletion</div>
      <div className='paragraph'>
        You have the right to request that we delete the Personal Data that we have collected about you. Under the CCPA, this right is subject to
        certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or complete a transaction or other
        action you have requested. If your deletion request is subject to one of these exceptions, we may deny your deletion request.
      </div>
      <div className='paragraph'>Exercising Your Rights</div>
      <div className='paragraph'>
        To exercise your right to know or your right to deletion, you must email your request to us at legal@onoonline.co from the email address
        associated with your ono online account or call us at (808) - 800 - 3311 and (1) provide sufficient information such as email address and
        phone number associated with your ono online account and your account name (if applicable) to allow us to verify that you are the person about
        whom we have collected Personal Data, and (2) describe your request in sufficient detail to allow us to understand, evaluate and respond to
        it.
      </div>
      <div className='paragraph'>
        Each request that meets both of these criteria will be considered a “Valid Request.” We may not respond to requests that do not meet these
        criteria. We will only use Personal Data provided in a Valid Request to verify your identity and complete your request. For safety and to
        otherwise prevent fraud, we may ask that you provide us with additional information that’s reasonably necessary for us to confirm your
        identity. You do not need an account to submit a Valid Request. We will work to respond to your Valid Request within 45 days of receipt. We
        will not charge you a fee for making a Valid Request unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we
        determine that your Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing your request.
      </div>
      <div className='paragraph'>
        You may also authorize an agent (an “Authorized Agent”) to exercise your rights on your behalf. To do this, you must provide your Authorized
        Agent with written permission to exercise your rights on your behalf, and we may request a copy of this written permission from your
        Authorized Agent when they make a request on your behalf.
      </div>
      <div className='paragraph'>Personal Data Sales Opt-Out and Opt-In</div>
      <div className='paragraph'>
        As described in the “Tracking Tools, Advertising and Opt-Out” section above, we have incorporated Cookies from certain third parties into our
        Services. These Cookies allow those third parties to receive information about your activity on our Services that is associated with your
        browser or device. Those third parties may use that data to serve you relevant ads on our Services or on other websites you visit. Plus, some
        sharing of personal information is necessary to connect you with restaurant partners where you have previously placed orders so that they can
        share exclusive offers with you. Even though ono online does not sell any of your information in the traditional sense of the word, the
        data-sharing relationship we have with advertising partners and restaurant partners is considered a “sale” under the CCPA (even if no money is
        exchanged). You can opt out of these sales by following the instructions in this section.
      </div>
      <div className='paragraph'>The categories of third parties to whom we sell or disclose your personal information include:</div>
      <div className='paragraph'>Restaurant partners;</div>
      <div className='paragraph'>Our affiliates;</div>
      <div className='paragraph'>Marketing and promotional partners;</div>
      <div className='paragraph'>Ad networks and advertising partners.</div>
      <div className='paragraph'>
        In the preceding 12 months, we or our service providers may have collected or sold the below categories of personal information:
      </div>
      <div className='paragraph'>Identifiers such as your name, email address and similar identifiers;</div>
      <div className='paragraph'>Commercial information such as records of products or services purchased.</div>
      <div className='paragraph'>You have the right to opt-out of the sale of your Personal Data. You can opt-out using the following methods:</div>
      <div className='paragraph'>You can complete the online form found here: Do Not Sell My Personal Information.</div>
      <div className='paragraph'>Email us at legal@onoonline.co.</div>
      <div className='paragraph'>
        Once you have submitted an opt-out request, we will not ask you to reauthorize the sale of your Personal Data for at least 12 months.
      </div>
      <div className='paragraph'>To our knowledge, we do not sell the Personal Data of minors under 16 years of age.</div>
      <div className='paragraph'>We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA</div>
      <div className='paragraph'>
        We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our goods or services, charge you
        different prices or rates, or provide you a lower quality of goods and services if you exercise your rights under the CCPA. However, we may
        offer different tiers of our Services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates or levels of
        quality of the goods or services you receive related to the value of Personal Data that we receive from you.
      </div>
      <div className='paragraph'>
        <h3>Other State Law Privacy Rights</h3>
      </div>
      <div className='paragraph'>California Resident Rights</div>
      <div className='paragraph'>
        Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data
        to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at
        legal@onoonline.co.
      </div>
      <div className='paragraph'>Nevada Resident Rights</div>
      <div className='paragraph'>
        If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or
        sell that Personal Data. You can exercise this right by contacting us at legal@onoonline.co with the subject line “Nevada Do Not Sell Request”
        and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal
        Data as sales are defined in Nevada Revised Statutes Chapter 603A.
      </div>
      <div className='paragraph'>
        <h3>Changes to this Privacy Policy</h3> We’re constantly trying to improve our Services, so we may need to change this Privacy Policy from
        time to time, but we will alert you to any such changes by placing a notice on the ono online website, by sending you an email and/or by some
        other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address),
        those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use
        the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we
        collect is subject to the Privacy Policy in effect at the time such information is collected.
      </div>
      <div className='paragraph'>
        <h3>Contact Information:</h3>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your
        Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:{' '}
      </div>
      <div className='paragraph'>(808) 800-3311</div>
      <div className='paragraph'>onoonline.co</div>
      <div className='paragraph'>support@onoonline.co</div>
      <div className='paragraph'>1441 Kapiolani Blvd, Ste 1115, Honolulu, HI 96814</div>
    </IonContent>
  );
};

export default PrivacyPolicy;
