import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsOfUse from '../components/TermsOfUse';
import './Page.css';

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {/* <IonTitle>{name}</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>

            <IonTitle size="large">{name === 'privacypolicy' ? 'Privacy policy' : 'Term of use'}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {name === 'privacypolicy' ?
          <PrivacyPolicy name={name} />
          : <TermsOfUse name={name} />
        }
      </IonContent>
    </IonPage>
  );
};

export default Page;
