import { IonContent } from '@ionic/react';
import './style.css';

const TermsOfUse = ({ name }) => {
  return (
    <IonContent className='ion-padding container'>
      <h3>Effective Date: April 19, 2022</h3>
      <h5>Terms of Use</h5>
      <h5>Effective Date: April 19, 2022</h5>
      <div className='paragraph'>
        Welcome to the ono online platform. Please read on to learn the rules and restrictions that govern your use of our website(s), products,
        services and applications (the “Services”). If you have any questions, comments, or concerns regarding these terms or the Services, please
        contact us at:
      </div>
      <div className='white'>Email: info@onoonline.co</div>
      <div className='white'>Phone: (808) 800-3311</div>
      <div className='white'>Address: 1441 Kapiolani Blvd, Ste 1115, Honolulu, HI 96814</div>
      <div className='paragraph'>
        These Terms of Use (the “Terms”) are a binding contract between you and STOVE LLC. (“ono online,” “we” and “us”). Your use of the Services in
        any way means that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These Terms include the
        provisions in this document as well as those in the Privacy Policy, and any other relevant policies. Your use of or participation in certain
        Services may also be subject to additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference,
        and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms.{' '}
      </div>
      <div className='paragraph'>
        Please read these Terms carefully. They cover important information about Services provided to you. These Terms include information about
        future changes to these Terms, limitations of liability, a class action waiver and resolution of disputes by arbitration instead of in court.
        PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU
        MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.{' '}
      </div>
      <div className='paragraph'>
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU
        AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS
        ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.{' '}
      </div>
      <h3>Will these Terms ever change?</h3>
      <div className='paragraph'>
        {' '}
        We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change
        the Terms at any time, but if we do, we will place a notice on our site located at onoonline.co, send you an email, and/or notify you by some
        other means. If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use
        the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes. Except
        for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both
        you and us.{' '}
      </div>
      <h3>What about my privacy?</h3>{' '}
      <div className='paragraph'>
        ono online takes the privacy of its users very seriously. For the current ono online Privacy Policy, please click here. Children’s Online
        Privacy Protection Act The
      </div>{' '}
      <h5>Children’s Online Privacy Protection Act</h5>
      <div className='paragraph'>
        {' '}
        (“COPPA”) requires that online service providers obtain parental consent before they knowingly collect personally identifiable information
        online from children who are under 13 years of age. We do not knowingly collect or solicit personally identifiable information from children
        under 16 years of age; if you are a child under 16 years of age, please do not attempt to register for or otherwise use the Services or send
        us any personal information. If we learn we have collected personal information from a child under 16 years of age, we will delete that
        information as quickly as possible. If you believe that a child under 16 years of age may have provided us personal information, please
        contact us at info@onoonline.co.{' '}
      </div>
      <h3>What are the basics of using ono online?</h3>
      <div className='paragraph'>
        You may be required to sign up for an account, select a password and username (“ono online User ID”), and provide us with certain information
        or data, such as your contact information, valid credit or debit card. You promise to provide us with accurate, complete, and updated
        registration information about yourself. You may not select as your ono online User ID a name that you do not have the right to use, or
        another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written
        permission.
      </div>{' '}
      <div className='paragraph'>
        To ensure the required level of payment security, ono online currently uses Stripe to collect payments from Users on behalf of Restaurants.
        ono online reserves the right to change its payment provider at its discretion, provided any such payment provider meets the security
        compliance level above. Upon confirmation of the contact information, you will be connected to ono online to complete the sign up details by
        providing credit or debit card details. Stripe will verify the credit or debit card details prior to confirming the User. If the credit or
        debit cards cannot be verified by Stripe, ono online reserves the right to refuse the User access to the App.{' '}
      </div>
      <div className='paragraph'>
        Additionally, you may be able to access certain parts or features of the Services by using your account credentials from other services (each,
        a “Third Party Account”), such as those offered by Google, Facebook or Apple. By using the Services through a Third Party Account, you permit
        us to access certain information from such account for use by the Services. You are ultimately in control of how much information is
        accessible to us and may exercise such control by adjusting your privacy settings on your Third Party Account.{' '}
      </div>
      <div className='paragraph'>
        You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or
        guardian’s permission to use the Services and have gotten your parent or guardian to agree to these Terms on your behalf). If you’re agreeing
        to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that
        organization’s or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for
        in this sentence, refer to that organization or entity).{' '}
      </div>
      <div className='paragraph'>
        You will only use the Services for your own internal, personal use, and not on behalf of or for the benefit of any third party. You will
        comply with all laws that apply to you, your use of the Services, and your actions and omissions that relate to the Services (for example,
        Restaurants must comply with all laws that relate to the Restaurant Orders (as defined below)). If your use of the Services is prohibited by
        applicable laws, then you aren’t authorized to use the Services. We can’t and won’t be responsible for your using the Services in a way that
        breaks the law.{' '}
      </div>
      <div className='paragraph'>
        You will not share your ono online User ID, account or password with anyone, and you must protect the security of your ono online User ID,
        account, password and any other access tools or credentials. You’re responsible for any activity associated with your ono online User ID and
        account.{' '}
      </div>
      <div className='paragraph'>
        You can only have one ono online account at any time. We do not allow Users to have multiple accounts for the same individual. Attempting to
        and/or establishing multiple accounts for the same user (even if such accounts are registered with different profiles, emails, phone numbers,
        payment methods, and etc.) violates this Agreement. We reserve the right to take corrective action at our sole discretion (including but not
        limited to suspending or terminating Service and/or deleting accounts) in the event you attempt to and/or establish multiple accounts with us.
      </div>
      <h5>Connecting Restaurants and Diners</h5>{' '}
      <div className='paragraph'>
        ono online connects those providing services (“Restaurants”) and those looking to acquire their services (“Diners”). When we use the word
        “you” in these Terms, it refers to any user, regardless of whether he or she is a Restaurant or Diner, while if we use one of those specific
        terms, it only applies to that category of user.{' '}
      </div>
      <div className='paragraph'>
        Before making any order from any Restaurant (“Restaurant Order”, or “Order”), Diners are responsible for making their own determinations that
        the recommended Restaurant is suitable. ono online is only responsible for connecting Restaurants and Diners, and can’t and won’t be
        responsible for making sure that Restaurant Order are actually provided or are up to a certain standard of quality. It is the Diner's
        responsibility to ensure that the Order is accurate and reflects the goods purchased from the Restaurant. ono online is not responsible for
        ensuring the accuracy of the Order. Any queries or issues with the total of the Order should be directed to the Restaurant. ono online's
        obligation is only to facilitate the payment of the amount shown on the Order. ono online similarly can’t and won’t be responsible for
        ensuring that information (including credentials) a Diner or Restaurant provides about himself or herself is accurate or up-to-date. We don’t
        control the actions of any Diner or Restaurant, and Restaurants aren’t our employees.{' '}
      </div>{' '}
      <div className='paragraph'>
        It is the Diner’s responsibility to ensure that any and all Users on the Order are at the Restaurant at the time for which the Order is
        scheduled. The Diner agrees to physically be at the Restaurant at the time for which the Restaurant Order is scheduled when he or she clicks
        “Place Order” on the App. ono online is not responsible for any additional charges the Diner may incur from the Restaurant due to being late
        to the time when the Restaurant Order is scheduled.{' '}
      </div>
      <h5>
        Restaurants are independent contractors of Diners and not employees, partners, representatives, agents, joint venturers, independent
        contractors or franchisees of ono online. ono online does not offer the Restaurant Orders and does not employ individuals to perform the
        Restaurant Orders. You hereby acknowledge that ono online does not supervise, direct, control or monitor the Restaurant Orders and expressly
        disclaims any responsibility and liability for the Restaurant Orders, including but not limited to any food safety regulation, warranty or
        condition of good and workmanlike services, warranty or condition of quality or fitness for a particular purpose, or compliance with any law,
        regulation, or code.{' '}
      </h5>
      <h3>What about refunds and cancellations?</h3>{' '}
      <div className='paragraph'>
        All charges and payments will be enabled by ono online using the preferred payment method designated in your account, after which you will
        receive a receipt by email. If your primary account payment method is determined to be expired, invalid or otherwise not able to be charged,
        you agree that ono online may use a secondary payment method in your account, if available. Charges paid by you are final and non-refundable,
        unless otherwise determined by ono online.{' '}
      </div>
      <div className='paragraph'>
        Non-refundable cases include but are not limited to: late food arrival time; incomplete Order; portion size not as expected; food quality;
        poor packaging; restaurant service; menu price difference; placing an Order at the wrong restaurant location.{' '}
      </div>
      <div className='paragraph'>
        You can cancel your Order anytime after submitting it, but before the Restaurant starts preparing the Order. The status of your Order is
        reflected on the app. If you would like to cancel an Order after the restaurant has started preparing it, you can call the restaurant to ask
        them to cancel your Order. In this case, the refund is processed only upon the restaurant’s confirmation. If the restaurant does not agree to
        cancel the Order, we will be unable to issue a full refund for the Order.{' '}
      </div>
      <h3>What about messaging?</h3>
      <div className='paragraph'>
        As part of the Services, you may receive communications through the Services, including messages that ono online sends you (for example, via
        email or SMS). When signing up for the Services, you will receive a welcome message and instructions on how to stop receiving messages.{' '}
        <span className='bold'>
          By signing up for the Services and providing us with your wireless number, you confirm that you want ono online to send you information that
          we think may be of interest to you, which may include ono online using automated dialing technology to text you at the wireless number you
          provided, and you agree to receive communications from ono online, and you represent and warrant that each person you register for the
          Services or for whom you provide a wireless phone number has consented to receive communications from ono online.
        </span>
        You agree to indemnify and hold ono online harmless from and against any and all claims, liabilities, damages (actual and consequential),
        losses and expenses (including attorneys’ fees) arising from or in any way related to your breach of the foregoing.
      </div>
      <h3>Are there restrictions in how I can use the Services?</h3>
      <div className='paragraph'>
        ono online will supply the App to you from the date that your details are confirmed. ono online will make every effort to ensure the App is
        operational 24 hours a day, 7 days a week. However, there may be issues or delays due to events outside ono online’ control. ono online may
        have to suspend the App if it has to deal with technical problems, or to make improvements. ono online will contact you to let you know in
        advance where this occurs, unless the problem is urgent or an emergency. This does not affect your obligation to pay any outstanding amounts
        on open Orders (as may be notified to the Users by ono online)
      </div>
      <div className='paragraph'>
        In the unlikely event that there is any defect with the App: - please contact ono online as soon as reasonably possible; - please give ono
        online a reasonable opportunity to repair or fix any defect;
      </div>
      <div className='paragraph'>
        ono online will use every effort to repair or fix the defect as soon as reasonably practicable. You will not have to pay for ono online to
        repair or fix a defect with the App.
      </div>
      <div className='paragraph'>
        You represent, warrant, and agree that you will not provide or contribute anything, including any Content or User Submission (as those terms
        are defined below), to the Services, or otherwise use or interact with the Services, in a manner that:{' '}
      </div>
      <div className='paragraph'>
        (a) infringes or violates the intellectual property rights or any other rights of anyone else (including ono online);
      </div>
      <div className='paragraph'>
        (b) violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not
        reasonably intended by ono online;
      </div>
      <div className='paragraph'>
        (c) is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;
      </div>
      <div className='paragraph'>
        (d) jeopardizes the security of your ono online User ID, account or anyone else’s (such as allowing someone else to log in to the Services as
        you);
      </div>
      <div className='paragraph'>
        (e) attempts, in any manner, to obtain the password, account, or other security information from any other user;
      </div>
      <div className='paragraph'>(f) violates the security of any computer network, or cracks any passwords or security encryption codes;</div>
      <div className='paragraph'>
        (g) runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are
        not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load
        on the Services’ infrastructure);
      </div>
      <div className='paragraph'>
        (h) “crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated
        means);
      </div>
      <div className='paragraph'>(i) copies or stores any significant portion of the Content; or </div>
      <div className='paragraph'>
        (j) decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the
        Services.{' '}
      </div>
      <h3>A VIOLATION OF ANY OF THE FOREGOING IS GROUNDS FOR TERMINATION OF YOUR RIGHT TO USE OR ACCESS THE SERVICES.</h3>
      <div className='paragraph'>
        Without limiting the generality of the foregoing, your account may be banned if you violate any provision in these User Terms. Ban may result
        in your being charged the amount of ono online credits used in your account. Repeated violations may result in your being charged a $10 (USD)
        fee per violation (the “Violation Fee”). In particular, the following violations are subject to a ban and Violation Fee:
      </div>
      <div className='paragraph'>
        {`  `}If you (or someone authorized by you) create multiple accounts, or use fake phone numbers or fake email addresses, in order to misuse
        ono online credits.
      </div>
      <div className='paragraph'>
        {`  `}If you (or someone authorized by you) make a reservation at a Restaurant using the ono online Services but arrive at the Restaurant more
        than thirty (30) minutes before your reservation time and attempt to claim your reservation.
      </div>
      <div className='paragraph'>
        {`  `}If you (or someone authorized by you) use the ono online Services to make a reservation or order takeout at restaurants where ono online
        does not provide such services.
      </div>
      <div className='paragraph'>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</div>
      <h3> What are my rights in the Services?</h3>
      <div className='paragraph'>
        The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles,
        photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the “Content”) are protected by
        copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and
        restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish,
        broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not
        owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including ono
        online's) rights.
      </div>
      <div className='paragraph'>
        Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use
        (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or
        storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You
        understand that ono online owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except
        as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you
        to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section
        still apply.
      </div>
      <h3>What about anything I contribute to the Services – do I have to grant any licenses to ono online or to other users?</h3>
      <div className='paragraph'>User Submissions</div>
      <div className='paragraph'>
        Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission”. Some User Submissions may be
        viewable by other users. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User
        Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.
      </div>
      <div className='paragraph'>
        You agree that you will not post, upload, share, store, or otherwise provide through the Services any User Submissions that: (i) infringe any
        third party's copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii)
        contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict
        unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes,
        multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other
        dubious money-making ventures; or (viii) that violate any law.
      </div>
      <div className='paragraph'>Licenses</div>
      <div className='paragraph'>
        In order to display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain
        rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our Privacy
        Policy to the extent they relate to User Submissions that are also your personally-identifiable information.
      </div>
      <div className='paragraph'>
        By submitting User Submissions through the Services, you hereby do and shall grant ono online a worldwide, non-exclusive, perpetual,
        royalty-free, fully paid, sublicensable and transferable license to use, edit, modify, truncate, aggregate, reproduce, distribute, prepare
        derivative works of, display, perform, and otherwise fully exploit the User Submissions in connection with this site, the Services and our
        (and our successors’ and assigns’) businesses, including without limitation for promoting and redistributing part or all of this site or the
        Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party
        websites and feeds), and including after your termination of your account or the Services. You also hereby do and shall grant each user of
        this site and/or the Services a non-exclusive, perpetual license to access your User Submissions through this site and/or the Services, and to
        use, edit, modify, reproduce, distribute, prepare derivative works of, display and perform such User Submissions, including after your
        termination of your account or the Services. For clarity, the foregoing license grants to us and our users do not affect your other ownership
        or license rights in your User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise agreed
        in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or violation of any third
        party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other
        intellectual property or proprietary rights.
      </div>
      <div className='paragraph'>
        Certain features of the Services allow you to share information with others, including through your social networks or other Third Party
        Accounts. When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may
        redistribute it, usually by providing a “share” button on or near the Content. If you share information from the Services with others through
        your Third Party Accounts, such as your social networks, you authorize ono online to share that information with the applicable Third Party
        Account provider. Please review the policies of any Third Party Account providers you share information with or through for additional
        information about how they may use your information. If you redistribute Content, you must be able to edit or delete any Content you
        redistribute, and you must edit or delete it promptly upon our request.
      </div>
      <div className='paragraph'>
        Finally, you understand and agree that ono online, in performing the required technical steps to provide the Services to our users (including
        you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of
        connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.
      </div>
      <h3>What if I see something on the Services that infringes my copyright?</h3>
      <div className='paragraph'>
        In accordance with the DMCA, we’ve adopted the following policy toward copyright infringement. We reserve the right to (1) block access to or
        remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our
        advertisers, affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders. Procedure for
        Reporting Copyright Infringements. If you believe that material or content residing on or accessible through the Services infringes your
        copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a notice of copyright infringement containing
        the following information to ono online's Designated Agent to Receive Notification of Claimed Infringement (our “Designated Agent,” whose
        contact details are listed below):
      </div>
      <div className='paragraph'>
        (a) A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly
        infringed;
      </div>
      <div className='paragraph'>(b) Identification of works or materials being infringed;</div>
      <div className='paragraph'>
        (c) Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials
        that the copyright owner seeks to have removed, with sufficient detail so that Company is capable of finding and verifying its existence;
      </div>
      <div className='paragraph'>
        (d) Contact information about the notifier including address, telephone number and, if available, email address;
      </div>
      <div className='paragraph'>
        (e) A statement that the notifier has a good faith belief that the material identified in (1)(c) is not authorized by the copyright owner, its
        agent, or the law; and A statement made under penalty of perjury that the information provided is accurate and the notifying party is
        authorized to make the complaint on behalf of the copyright owner.
      </div>
      <div className='paragraph'>
        Once Proper Bona Fide Infringement Notification is Received by the Designated Agent. Upon receipt of a proper notice of copyright
        infringement, we reserve the right to:
      </div>
      <div className='paragraph'>(a) remove or disable access to the infringing material;</div>
      <div className='paragraph'>
        (b) notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and
      </div>
      <div className='paragraph'>(c) terminate such content provider's access to the Services if he or she is a repeat offender. </div>
      <div className='paragraph'>
        (3) Procedure to Supply a Counter-Notice to the Designated Agent. If the content provider believes that the material that was removed (or to
        which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the
        copyright owner, the copyright owner's agent, or, pursuant to the law, the content provider may send us a counter-notice containing the
        following information to the Designated Agent:
      </div>
      <div className='paragraph'>(a) A physical or electronic signature of the content provider;</div>
      <div className='paragraph'>
        (b) Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared
        before it was removed or disabled;
      </div>
      <div className='paragraph'>
        (c) A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or
        misidentification of the material; and
      </div>
      <div className='paragraph'>
        (d) Content provider's name, address, telephone number, and, if available, email address, and a statement that such person or entity consents
        to the jurisdiction of the Federal Court for the judicial district in which the content provider’s address is located, or, if the content
        provider's address is located outside the United States, for any judicial district in which Company is located, and that such person or entity
        will accept service of process from the person who provided notification of the alleged infringement.
      </div>
      <div className='paragraph'>
        If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the counter-notice to the original
        complaining party informing that person that Company may replace the removed material or cease disabling it in 10 business days. Unless the
        copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material
        may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Company's discretion.
      </div>
      <div className='paragraph'>Please contact ono online's Designated Agent at the following address:</div>
      <div className='paragraph'>
        <div className='white'>STOVE LLC.</div>
        <div className='white'>1441 Kapiolani Blvd Ste 1115, Honolulu, HI 96814</div>
      </div>
      <h3>Who is responsible for what I see and do on the Services?</h3>{' '}
      <div className='paragraph'>
        Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom
        such Content originated, and you access all such information and Content at your own risk, and we aren’t liable for any errors or omissions in
        that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any
        action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and
        you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity
        of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.
      </div>
      <div className='paragraph'>
        You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights
        necessary to do so, in the manner in which you contribute it.
      </div>
      <div className='paragraph'>
        The Services may contain links or connections to third-party websites or services that are not owned or controlled by ono online. When you
        access third-party websites or use third-party services, you accept that there are risks in doing so, and that ono online is not responsible
        for such risks.
      </div>
      <div className='paragraph'>
        ono online has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions
        expressed in any third-party websites or by any third party that you interact with through the Services. In addition, ono online will not and
        cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the
        Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using
        the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.
      </div>
      <div className='paragraph'>
        If there is a dispute between participants on this site or Services, or between users and any third party, you agree that ono online is under
        no obligation to become involved. In the event that you have a dispute with one or more other users, you release ono online, its directors,
        officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or
        unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby do
        waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance: “A general release does not extend
        to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and
        that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.”
      </div>
      <h3>Will ono online ever change the Services?</h3>{' '}
      <div className='paragraph'>
        We’re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may
        introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice
        when we make a material change to the Services that would adversely affect you, but this isn’t always practical. We reserve the right to
        remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that
        Content in violation of these Terms), in our sole discretion, and without notice.
      </div>
      <h3>Do the Services cost anything?</h3>
      <div className='paragraph'>
        The Services may contain a fee, which is described on our App and on our website. We reserve the right to change our fees for certain or all
        Services in the future. We will notify you before changing the fees of any Services you are then using begin carrying a fee, and if you wish
        to continue using such Services, you must pay all applicable fees for such Services. If you are a Restaurant, we will charge the services fees
        outlined in our separate Restaurant Agreement.
      </div>
      <h3>What if I want to stop using the Services?</h3>
      <div className='paragraph'>
        You’re free to do that at any time by contacting us at info@onoonline.co; please refer to our Privacy Policy, as well as the licenses above,
        to understand how we treat information you provide to us after you have stopped using our Services.
      </div>
      <div className='paragraph'>
        ono online is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion,
        including your breach of these Terms. ono online has the sole right to decide whether you are in violation of any of the restrictions set
        forth in these Terms; for example, a Diner who believes that a Restaurant may be in breach of these Terms is not able to enforce these Terms
        against that Restaurant.
      </div>
      <div className='paragraph'>
        Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate
        your account.
      </div>
      <div className='paragraph'>
        If you have deleted your account by mistake, contact us immediately at info@onoonline.co – we will try to help, but unfortunately, we can’t
        promise that we can recover or restore anything.
      </div>
      <div className='paragraph'>
        Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following
        will survive termination: any obligation you have to indemnify us, any limitations on our liability, any terms regarding ownership or
        intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.
      </div>
      <h3>What about Mobile Applications?</h3>
      <div className='paragraph'>
        You acknowledge and agree that the availability of our mobile application is dependent on the third party stores from which you download the
        application, e.g., the App Store from Apple or the Android app market from Google (each an “App Store”). Each App Store may have its own terms
        and conditions to which you must agree before downloading mobile applications from such store, including the specific terms relating to Apple
        App Store set forth below. You agree to comply with, and your license to use our application is conditioned upon your compliance with, such
        App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise
        conflict with, the terms and conditions of these Terms of Use, the more restrictive or conflicting terms and conditions in these Terms of Use
        apply.
      </div>
      <h3>I use the ono online App available via the Apple App Store – should I know anything about that?</h3>
      <div className='paragraph'>
        These Terms apply to your use of all the Services, including our iOS applications (the “Application”) available via the Apple, Inc. (“Apple”)
        App Store, but the following additional terms also apply to the Application
      </div>
      <div className='paragraph'>
        (a) Both you and ono online acknowledge that the Terms are concluded between you and ono online only, and not with Apple, and that Apple is
        not responsible for the Application or the Content;
      </div>
      <div className='paragraph'>
        (b) The Application is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in
        connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they
        are applicable to the Services;
      </div>
      <div className='paragraph'>(c) You will only use the Application in connection with an Apple device that you own or control;</div>
      <div className='paragraph'>
        (d) You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the
        Application;
      </div>
      <div className='paragraph'>
        (e) In the event of any failure of the Application to conform to any applicable warranty, including those implied by law, you may notify Apple
        of such failure; upon notification, Apple’s sole warranty obligation to you will be to refund to you the purchase price, if any, of the
        Application;
      </div>
      <div className='paragraph'>
        (f) You acknowledge and agree that ono online, and not Apple, is responsible for addressing any claims you or any third party may have in
        relation to the Application;
      </div>
      <div className='paragraph'>
        (g) You acknowledge and agree that, in the event of any third-party claim that the Application or your possession and use of the Application
        infringes that third party’s intellectual property rights, ono online, and not Apple, will be responsible for the investigation, defense,
        settlement and discharge of any such infringement claim;
      </div>
      <div className='paragraph'>
        (h) You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that has been designated by the
        U.S. Government as a “terrorist supporting” country, and that you are not listed on any U.S. Government list of prohibited or restricted
        parties;
      </div>
      <div className='paragraph'>
        (i) Both you and ono online acknowledge and agree that, in your use of the Application, you will comply with any applicable third-party terms
        of agreement which may affect or be affected by such use; and
      </div>
      <div className='paragraph'>
        (j) Both you and ono online acknowledge and agree that Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms, and that
        upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against
        you as the third-party beneficiary hereof.
      </div>
      <h3>Can I refer other users?</h3>
      <div className='paragraph'>
        From time to time ono online may offer rewards or incentives for referring others to the Services. For details of any current referral offers,
        please visit our Help Center (Credits and Promotions). The referring user (“Referrer”) may refer individuals or entities who are neither
        current customers of ono online nor registered users of the Services (“Referee”). A registered user is a person or entity that already has an
        existing account with ono online. There is no limit to the number of referrals that Referrer can make, nor the cumulative rewards or
        incentives that the Referrer may receive through such special offer, unless otherwise indicated. Referrer will receive the stated reward or
        incentive for each Referee sent by the Referrer that completes the required action described in that specific offer (such as signing up for an
        account or making a purchase). All Referees must be first-time recipients of the offer, and multiple referrals to the same individual or
        entity will be disregarded. ono online reserves the right to modify or terminate any special offers at any time and to revoke from Referrer
        and Referee the special offer at ono online's discretion for any reason or for no reason whatsoever. If ono online determines that Referrer or
        Referee is attempting to obtain unfair advantage or otherwise violate the terms or spirit of such special offer, ono online reserves the right
        to (a) revoke any rewards or incentives issued to either Referrer or Referee and/or (b) charge the Referrer or Referee for any rewards or
        incentives (1) used by Referrer or Referee prior to such revocation or (2) issued by ono online to any ineligible Referrer or Referee. All
        special offers are subject to any other terms, conditions and restrictions set forth on the Services or presented in connection with the
        special offer.
      </div>
      <h3>Are there any other promotional offers or credits?</h3>
      <div className='paragraph'>
        ono online may, in its sole discretion, create promotional codes that may be redeemed for account Credits (“Credits”) or other features or
        benefits related to our Services or services provided by Restaurants or other third parties, subject to terms that ono online establishes,
        including without limitation, on a per promotional code basis ("Promo Codes"). You agree that Promo Codes: (1) must be used for the intended
        audience and purpose, and in a lawful manner; (2) may not be duplicated, sold or transferred in any manner, including, without limitation,
        between accounts, or made available to the general public, unless expressly permitted by ono online; (3) may be disabled by ono online at any
        time for any reason without liability to ono online; (4) may only be used pursuant to the specific terms that ono online establishes for such
        Promo Code(s); (5) are not valid for cash; and (6) may expire prior to your use. We reserve the right to suspend your Account withhold or
        deduct Credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event that we determine
        or believe that the use or redemption of the Promo Code was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms
        or these Terms.
      </div>
      <div className='paragraph'>
        Furthermore, ono online may, in its sole discretion, allow participation in other promotional campaigns offered by ono online such as loyalty
        rewards program (“Rewards Program”). Rewards Program is made available through the Platform and where eligible users can earn points, Credits,
        receive ono online cash back credits (“ono online Cash Back Credits”) or other forms of rewards for taking certain actions on the Platform.
        Rewards Program includes, for example, “order X times to unlock Y% of ono online cash back credits”. Generally, by creating your ono online
        account and completing the first order, you will automatically be enrolled in the applicable Rewards Program to begin earning your Rewards.
        However, earning Rewards through Rewards Program may be based on a number of factors including but not limited to the volume and frequency of
        orders that you place. ono online may change the conditions of the Rewards Program at any time in its sole discretion.
      </div>
      <h3>What are ono online Cash Back Credits and how do they work?</h3>
      <div className='paragraph'>
        “ono online Cash Back Credits” are a virtual system made available by ono online to eligible users of the Services. If you are provided with
        ono online Cash Back Credits by us, we grant you a non-exclusive, revocable, personal, limited, non-transferrable (except as expressly set
        forth in the next paragraph) right and license to use the ono online Cash Back Credits only as allowed by the clear functionality of the
        Services.
      </div>
      <div className='paragraph'>
        Eligible users of the Services may receive ono online Cash Back Credits (and the license above) through various means that are expressly
        authorized by Services. In such event, we will credit your Services account for any ono online Cash Back Credits acquired by you as authorized
        in these Terms. ono online Cash Back Credits may be transferred by you to other Services users in exchange for access to the items, services,
        or content they make available through the Services. All transfers must only occur within, and using the functionality of, the ono online
        platform (meaning, you must not attempt to transfer ono online Cash Back Credits to any other person or entity outside of the functionality of
        the Services).
      </div>
      <div className='paragraph'>
        ono online CASH BACK CREDITS ARE NOT A REAL CURRENCY. EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN AN EXECUTED AGREEMENT BETWEEN YOU AND ono
        online, ono online CASH BACK CREDITS CANNOT BE SUBSTITUTED FOR REAL CURRENCY, HAVE NO MONETARY VALUE, AND CANNOT BE REDEEMED, REFUNDED, OR
        EXCHANGED FOR REAL CURRENCY. ono online CASH BACK CREDITS DO NOT EARN INTEREST. You acknowledge and agree that we may engage in actions that
        may impact the perceived value or acquired price of ono online Cash Back Credits at any time, except as prohibited by applicable law.
      </div>
      <div className='paragraph'>
        Transactions involving the exchange or ono online Cash Back Credits for virtual items, services, or content on via the Services are not
        legally enforceable, may not form the basis of any private right of action against ono online or any third party, and are governed solely by
        us in our sole discretion and application of these Terms.
      </div>
      <div className='paragraph'>
        All payments for ono online Cash Back Credits are final and not refundable or exchangeable, except as required by applicable law. You may not
        transfer, assign, sell, gift, exchange, trade, convert, lease, sublicense, rent, or distribute ono online Cash Back Credits except as allowed
        by ono online through the Services and as otherwise expressly authorized by us in writing. We do not recognize or condone any third-party
        services that may be used to sell, exchange, transfer, or otherwise dispose of ono online Cash Back Credits. We do not assume any
        responsibility for, will not be liable for, and will not facilitate or otherwise support, such transactions.
      </div>
      <div className='paragraph'>
        Any disposition or attempted disposition of ono online Cash Back Credits in violation of these Terms will be void and will result in immediate
        termination of your Service account and your license to use ono online Cash Back Credits. We, in our sole discretion, have the absolute right
        to manage, modify, suspend, revoke, and terminate your license to use ono online Cash Back Credits without notice, refund, compensation, or
        liability to you, except as otherwise prohibited by applicable law. Without limiting the foregoing, your license to use ono online Cash Back
        Credits will terminate upon termination of these Terms or your Services account and as otherwise provided herein, except as otherwise required
        by applicable law.
      </div>
      <h3>What else do I need to know?</h3>
      <div className='paragraph'>Warranty Disclaimer.</div>
      <div className='paragraph'>
        ono online and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers,
        directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and
        assigns (ono online and all such parties together, the “ono online Parties”) make no representations or warranties concerning the Services,
        including without limitation regarding any Content contained in or accessed through the Services, and the ono online Parties will not be
        responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services
        or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your
        participation in, the Services. The ono online Parties make no representations or warranties regarding suggestions or recommendations of
        services or products offered or purchased through or in connection with the Services, including any Restaurant Orders. THE SERVICES AND
        CONTENT ARE PROVIDED BY ono online (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE
        OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
        LIMITATIONS MAY NOT APPLY TO YOU.
      </div>
      <div className='paragraph'>
        Limitation of Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
        LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE ono online PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR
        (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS
        INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE
        GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF ONE-HUNDRED ($100) DOLLARS OR (D) ANY MATTER BEYOND OUR
        REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE
        LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
      </div>
      <div className='paragraph'>
        Indemnity. If you are a Diner, to the fullest extent allowed by applicable law, you agree to indemnify and hold the ono online Parties
        harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees)
        arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using
        your account), and (b) your violation of these Terms. In the event of such a claim, suit, or action (“Claim”), we will attempt to provide
        notice of the Claim to the contact information we have for your account (provided that failure to deliver such notice shall not eliminate or
        reduce your indemnification obligations hereunder).
      </div>
      <div className='paragraph'>
        Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way
        (by operation of law or otherwise) without ono online's prior written consent. We may transfer, assign, or delegate these Terms and our rights
        and obligations without consent.
      </div>
      <div className='paragraph'>
        Choice of Law. These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law, and the laws of
        the State of California, without regard to the conflicts of laws provisions thereof.
      </div>
      <div className='paragraph'>
        Arbitration Agreement. Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain disputes and
        claims with ono online and limits the manner in which you can seek relief from ono online. Both you and ono online acknowledge and agree that
        for the purposes of any dispute arising out of or relating to the subject matter of these Terms, ono online's officers, directors, employees
        and independent contractors (“Personnel”) are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms,
        Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party
        beneficiary hereof.
      </div>
      <div className='paragraph'>
        (a) Arbitration Rules; Applicability of Arbitration Agreement. The parties shall use their best efforts to settle any dispute, claim,
        question, or disagreement arising out of or relating to the subject matter of these Terms directly through good-faith negotiations, which
        shall be a precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall be finally settled
        by binding arbitration in Los Angeles County, California. The arbitration will proceed in the English language, in accordance with the JAMS
        Streamlined Arbitration Rules and Procedures (the “Rules”) then in effect, by one commercial arbitrator with substantial experience in
        resolving intellectual property and commercial contract disputes. The arbitrator shall be selected from the appropriate list of JAMS
        arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be entered in any court of competent
        jurisdiction.
      </div>
      <div className='paragraph'>
        (b) Costs of Arbitration. The Rules will govern payment of all arbitration fees. ono online will pay all arbitration fees for claims less than
        seventy-five thousand ($75,000) dollars. ono online will not seek its attorneys’ fees and costs in arbitration unless the arbitrator
        determines that your claim is frivolous.
      </div>
      <div className='paragraph'>
        (c) Small Claims Court; Infringement. Either you or ono online may assert claims, if they qualify, in small claims court in Los Angeles
        County, California or any United States county where you live or work. Furthermore, notwithstanding the foregoing obligation to arbitrate
        disputes, each party shall have the right to pursue injunctive or other equitable relief at any time, from any court of competent
        jurisdiction, to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade
        secrets, patents or other intellectual property rights.
      </div>
      <div className='paragraph'>
        (d) Waiver of Jury Trial. YOU AND ono online WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE
        OR JURY. You and ono online are instead choosing to have claims and disputes resolved by arbitration. Arbitration procedures are typically
        more limited, more efficient, and less costly than rules applicable in court and are subject to very limited review by a court. In any
        litigation between you and ono online over whether to vacate or enforce an arbitration award, YOU AND ono online WAIVE ALL RIGHTS TO A JURY
        TRIAL, and elect instead to have the dispute be resolved by a judge.
      </div>
      <div className='paragraph'>
        (e) Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR
        LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY
        OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class or consolidated actions is deemed invalid or
        unenforceable, neither you nor ono online is entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth
        in (g) below.
      </div>
      <div className='paragraph'>
        (f) Opt-out. You have the right to opt out of the provisions of this Section by sending written notice of your decision to opt out to the
        following address: 5630 Venice Blvd, Unit #549, Los Angeles, CA 90019 postmarked within thirty (30) days of first accepting these Terms. You
        must include (i) your name and residence address, (ii) the email address and/or telephone number associated with your account, and (iii) a
        clear statement that you want to opt out of these Terms’ arbitration agreement.
      </div>
      <div className='paragraph'>
        (g) Exclusive Venue. If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement permits
        either you or ono online to litigate any dispute arising out of or relating to the subject matter of these Terms in court, then the foregoing
        arbitration agreement will not apply to either party, and both you and ono online agree that any judicial proceeding (other than small claims
        actions) will be brought in the state or federal courts located in, respectively, Los Angeles County, California, or the federal district in
        which that county falls.
      </div>
      <div className='paragraph'>
        (h) Severability. If the prohibition against class actions and other claims brought on behalf of third parties contained above is found to be
        unenforceable, then all of the preceding language in this Arbitration Agreement section will be null and void. This arbitration agreement will
        survive the termination of your relationship with ono online.
      </div>
      <div className='paragraph'>
        Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments
        associated with your activity in connection with the Services, provided that the ono online may, in its sole discretion, do any of the
        foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be
        deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will
        be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and
        enforceable. You and ono online agree that these Terms are the complete and exclusive statement of the mutual understanding between you and
        ono online, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings
        relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture
        of ono online, and you do not have any authority of any kind to bind ono online in any respect whatsoever.
      </div>
      <div className='paragraph'>
        Except as expressly set forth in the sections above regarding the Apple Application and the arbitration agreement, you and ono online agree
        there are no third-party beneficiaries intended under these Terms.
      </div>
    </IonContent>
  );
};

export default TermsOfUse;
